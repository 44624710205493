<template>
  <div>
    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <ErrorMessage v-if="error" :error="error"></ErrorMessage>

    <div v-if="!isLoading">
      <div
        v-for="(group, groupIdx) in groupedSessions"
        :key="`key-${group.dtString}`"
        :class="groupIdx > 0 ? 'border-top' : ''"
        class="py-3"
      >
        <div v-if="isToday(group.dt)">
          <span class="text-danger">TODAY</span> - {{ group.dt.format('dddd, D MMM') }}
        </div>
        <div v-else>{{ group.dt.format('dddd, D MMM') }}</div>

        <div v-if="group.sessions.length === 0" class="my-3">
          No session<br>
          Have a rest... 😴
        </div>

        <SessionOverview
          v-for="session in group.sessions"
          :key="`key-${session.sessionId}`"
          :session="session"
          class="my-3"
        ></SessionOverview>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'AthleteUpcomingFeed',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SessionOverview: () => import('@/components/SessionOverview'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapState('feed', ['feedItems']),
    groupedSessions() {
      const { today } = this;
      const bucket = [{
        dt: today,
        dtString: today.format(dateFormat),
        sessions: [],
      }];
      this.feedItems.forEach((session) => {
        const dt = dayjs(session.date);
        const dtString = dt.format(dateFormat);
        const day = bucket.find((b) => b.dtString === dtString);
        if (day) {
          day.sessions.push(session);
        } else {
          bucket.push({
            dt,
            dtString,
            sessions: [session],
          });
        }
      });
      return bucket;
    },
  },
  methods: {
    async fetchFeed() {
      this.isLoading = true;
      try {
        const { today, numOfDays } = this;
        await this.$store.dispatch('feed/fetchFeed', {
          dateFrom: today.format(dateFormat),
          dateTo: today.add(numOfDays, 'day').format(dateFormat),
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    isToday(dt) {
      return dt.format(dateFormat) === this.today.format(dateFormat);
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      today: dayjs(),
      numOfDays: 7,
    };
  },
  created() {
    this.$store.dispatch('feed/resetFeed');
  },
  destroyed() {
    this.$store.dispatch('feed/resetFeed');
  },
  mounted() {
    this.fetchFeed();
  },
};
</script>
